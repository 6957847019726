import {
  CART_TYPE,
  GetCustomerResponse,
  getOneTimeAmountForUpfront,
  IFindCallerResponse,
  IGlobalConfigCMS,
  IPayments,
} from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { EMethodCode, IPayMean } from '@vfit/shared/models';
import { purify } from '@vfit/shared/themes';
import {
  IEscapeRegExp,
  IPrevPayment,
  IReplacePlaceHolderWithData,
} from './upfrontPaymentCard.models';

export const DEFAULT_CREDIT_CARD: IPayMean = {
  displayBrand: '*********',
  object_type: '*****',
  id: '***********',
  type: '*****',
  accountNumber: '*******',
  brand: '********',
  lastFourDigits: '****',
  holderName: '*********',
  expiryMonth: '**',
  expiryYear: '****',
};

export const organizePaymentMethod = (dataFromCms: IPayments) => ({
  title: dataFromCms?.paymentsmobile?.title || '',
  subtitle: dataFromCms?.paymentsmobile?.userRegistered?.description || '',
  buttonLabel: dataFromCms?.paymentsmobile?.userRegistered?.changeLabel || '',
});

export const organizePaymentMethodError = (paymentCMS: IPayments) => ({
  titlePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.title || '',
  messagePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.description || '',
  buttonPaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.buttonLabel || '',
  messageSelectedPaymentError: paymentCMS?.paymentsmobile?.paymentError?.notFound?.message || '',
  titleSelectedPaymentError: paymentCMS?.paymentsmobile?.paymentError?.notFound?.title || '',
  titleErrorErrorRetriveCart: paymentCMS?.paymentsmobile?.errorRetriveCart?.title || '',
  messageErrorRetriveCart: paymentCMS?.paymentsmobile?.errorRetriveCart?.description || '',
  buttonLabelErrorRetriveCart: paymentCMS?.paymentsmobile?.errorRetriveCart?.buttonLabel || '',
  titleErrorUpdatePaymentRecharge:
    paymentCMS?.paymentsmobile?.errorUpdatePaymentRecharge?.title || '',
  messageErrorUpdatePaymentRecharge:
    paymentCMS?.paymentsmobile?.errorUpdatePaymentRecharge?.description || '',
  buttonLabelErrorUpdatePaymentRecharge:
    paymentCMS?.paymentsmobile?.errorUpdatePaymentRecharge?.buttonLabel || '',
});

const checkNewMopEnabler = (cmsGlobalConfig?: IGlobalConfigCMS) => {
  const ENV_NEW_MOP_FROM_AWS = process.env['NEXT_PUBLIC_NEW_MOP_ENABLED'];
  const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
  const customerDataCF =
    customerData?.[0]?.owningIndividual?.fiscalCode || findCaller?.individualRef?.fiscalCode;
  const isGlobalNewMopUfrontMobileEnabled =
    cmsGlobalConfig?.globalconfig?.newMopUpfront?.enableNewMopMobile === 'true' ||
    ENV_NEW_MOP_FROM_AWS === 'TRUE' ||
    false;
  const isCustomerXBS = customerDataCF?.includes('XBS');
  return isCustomerXBS || isGlobalNewMopUfrontMobileEnabled;
};

export const organizeUpfrontSelectionCard = (
  dataFromCms: IPayments,
  cmsGlobalConfig?: IGlobalConfigCMS
) => {
  const isNewMopEnabled = checkNewMopEnabler(cmsGlobalConfig);

  const checkIfPayPal = (checkString: string) =>
    checkString?.toLowerCase() === EMethodCode.PAYPAL.toLowerCase();

  const oneTimeAmount = getOneTimeAmountForUpfront(true);

  const paymentMethods = dataFromCms?.paymentsmobile?.upFrontPayment?.paymentMethod
    ?.filter((el) => {
      if (isNewMopEnabled) return true;
      const allowedCodes = [
        EMethodCode.CREDIT_CARD?.toLowerCase(),
        EMethodCode.PAYPAL?.toLowerCase(),
      ];
      return allowedCodes.includes(el?.value?.toLowerCase());
    })
    .map((el) => ({
      title: el?.title || '',
      description: el?.description || '',
      value: checkIfPayPal(el?.value) ? 'creditCardAndPaypal' : el?.value,
    }));

  const escapeRegExp: IEscapeRegExp = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const replacePlaceHolderWithData: IReplacePlaceHolderWithData = (str, placeHolder, data) => {
    if (!str) return '';
    return (
      purify([
        str.replace(new RegExp(escapeRegExp(placeHolder), 'g'), `${data || ''}`) || '',
      ])?.[0] || ''
    ); // Escaped RegEx from $ needed for multiple placeholder occurrences in the template
  };

  return {
    titleUpFront: dataFromCms?.paymentsmobile?.upFrontPayment?.title || 'Contributo iniziale',
    descriptionCC: replacePlaceHolderWithData(
      dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionCC,
      CART_TYPE.PLACEHOLDER_ONETIME,
      oneTimeAmount
    ),
    descriptionCreditRemaning: replacePlaceHolderWithData(
      dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionCreditRemaining,
      CART_TYPE.PLACEHOLDER_ONETIME,
      oneTimeAmount
    ),
    descriptionDC: replacePlaceHolderWithData(
      dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionDC,
      CART_TYPE.PLACEHOLDER_ONETIME,
      oneTimeAmount
    ),
    descriptionPrepaid: replacePlaceHolderWithData(
      dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionPrepaid,
      CART_TYPE.PLACEHOLDER_ONETIME,
      oneTimeAmount
    ),
    descriptionPaypal: replacePlaceHolderWithData(
      dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionPaypal,
      CART_TYPE.PLACEHOLDER_ONETIME,
      oneTimeAmount
    ),
    titleCC: replacePlaceHolderWithData(
      dataFromCms?.paymentsmobile?.upFrontPayment?.titleCC,
      CART_TYPE.PLACEHOLDER_ONETIME,
      oneTimeAmount
    ),
    titleDC: replacePlaceHolderWithData(
      dataFromCms?.paymentsmobile?.upFrontPayment?.titleDC,
      CART_TYPE.PLACEHOLDER_ONETIME,
      oneTimeAmount
    ),
    titlePrepaid: replacePlaceHolderWithData(
      dataFromCms?.paymentsmobile?.upFrontPayment?.titlePrepaid,
      CART_TYPE.PLACEHOLDER_ONETIME,
      oneTimeAmount
    ),
    titlePaypal: replacePlaceHolderWithData(
      dataFromCms?.paymentsmobile?.upFrontPayment?.titlePaypal,
      CART_TYPE.PLACEHOLDER_ONETIME,
      oneTimeAmount
    ),
    expirationLabelTitle: dataFromCms?.paymentsmobile?.paymentMethod?.expirationLabel || '',
    paymentMethod: paymentMethods,
    bottomModalConfig: {
      textLink: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.textLink || '',
      titleModal: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.title || '',
      descriptionModal: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.description || '',
    },
  };
};

export const isUpfrontNewMopEnabled = (
  cmsGlobalConfig: IGlobalConfigCMS,
  prevPaymentMethod?: IPrevPayment
): boolean => {
  const isNewMopEnabled = checkNewMopEnabler(cmsGlobalConfig);

  let availableMethodAlreadyPresent: EMethodCode[];
  if (isNewMopEnabled) {
    availableMethodAlreadyPresent = [
      EMethodCode.CREDIT_CARD,
      EMethodCode.DEBIT_CARD,
      EMethodCode.PREPAID_CARD,
    ];
  } else {
    availableMethodAlreadyPresent = [EMethodCode.CREDIT_CARD];
  }

  return availableMethodAlreadyPresent.includes(prevPaymentMethod?.value as EMethodCode) || false;
};
