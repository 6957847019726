import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  CONSUMER_CMS_SHOPPING_CART,
  errorMock,
  getLastPaymentMethod,
  IGenericErrorCMS,
  IPayments,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey, openPopup, trackView } from '@vfit/shared/data-access';
import { IPayMeanServiceResponse, ISIAFlowType } from '@vfit/shared/models';
import { resetData } from '@vfit/shared/data-access';
import { PaymentMethod } from '@vfit/consumer/components';
import { organizePaymentMethod, organizePaymentMethodError } from './paymentMethodCard.utils';
import { useCheckout } from '../../../iBuyMobile.context';
import { ID_FLOWS } from '../../checkout.constants';
import { ICheckoutData } from '../../checkout.models';
import { useUpdatePaymentMethodFlow } from '../../hooks/UseUpdatePaymentMethodFlow/useUpdatePaymentMethodFlow';
import TaggingPaymentMethodCard from './paymentMethodCard.tagging';

const PaymentMethodCard = ({
  handleOnChangeEnableGoNext,
  handleGoNextSlide,
  handleGoPrevSlide,
}: ICheckoutData) => {
  const queryClient = useQueryClient();
  const {
    customerFlow,
    checkoutErrors,
    owningData,
    isModalButtonSticky,
    product,
    setIsLastCard,
    setCurrentStepKey,
    setIsPreselectedCard,
  } = useCheckout();
  const [isSuccessChangePayment, setIsSuccessChangePayment] = useState(false);
  const paymentData = customerFlow?.payment?.data;
  const payMean: IPayMeanServiceResponse = getFromLocalStorageByKey('payMean');

  const selectedPaymean = getLastPaymentMethod(payMean as IPayMeanServiceResponse);

  // const isCreditCard =
  //   selectedPaymean?.type?.toLowerCase() === EMethodCode?.CREDIT_CARD.toLowerCase();

  const {
    isSuccess: isSuccessUpdatePaymentMethod,
    isError: isErrorUpdatePaymentMethod,
    error: errorUpdatePaymentMethod,
    handleResetState: resetPutPaymentState,
  } = useUpdatePaymentMethodFlow(
    paymentData?.orderPayment?.orderItemPayments?.[0]?.orderItemOnBillPayment
      ?.billingOfferIdForChangePaymentMethod || [''],
    paymentData?.orderPayment?.orderItemPayments?.[0]?.orderActionId || '',
    !isSuccessChangePayment,
    customerFlow?.payment?.data?.orderPayment?.orderOnBillPayment?.availablePaymentMethods?.find(
      (availablePayment) =>
        availablePayment.type?.toLowerCase() === selectedPaymean?.type?.toLowerCase()
    ),
    ISIAFlowType.RECURRENT,
    'verify'
  );

  const isFamilyPlus = product?.isFamilyPlusProduct;
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const paymentKeyCMS = isFamilyPlus
    ? API.CMS_GET_FAMILY_PLUS_PAYMENT
    : API.CMS_GET_PAYMENTS_MOBILE;
  const dataFromCms = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, paymentKeyCMS) as IPayments;
  const {
    title,
    subtitle,
    buttonLabel,
    yourIbanTitle,
    yourCreditCardTitle,
    yourDebitCardTitle,
    yourPrepaidCardTitle,
    yourPaypalTitle,
    expirationLabelTitle,
  } = organizePaymentMethod(dataFromCms);
  const { titlePaymentError, messagePaymentError, actionLabel } =
    organizePaymentMethodError(dataFromCms);

  const handleEnableNextStep = () => {
    if (handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(true);
  };

  const onChangePayment = () => {
    setIsLastCard(false);
    setTimeout(() => {
      trackView({
        event_name: 'ui_interaction',
        event_label_track: 'change paym method',
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
      if (handleGoNextSlide) handleGoNextSlide();
    }, 300);
  };

  useEffect(() => {
    const resetPaymentData = () => {
      resetData(queryClient, ['payMean', 'authorize']);
    };
    if (errorUpdatePaymentMethod) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      resetPaymentData();
      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        errorMock('authorize', { url: API.AUTHORIZE, status: '500', statusText: '500' }),
        titlePaymentError,
        messagePaymentError,
        action,
        actionLabel,
        () => openPopup(product?.genericErrorCallMeNow?.url || '', action),
        product?.removeCTC ? undefined : product?.paymentErrorCallMeNow?.label,
        isModalButtonSticky,
        true
      );
    }
    if (isErrorUpdatePaymentMethod) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      resetPaymentData();
      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        errorUpdatePaymentMethod || undefined,
        titlePaymentError,
        messagePaymentError,
        action,
        actionLabel,
        () => openPopup(product?.genericErrorCallMeNow?.url || '', action),
        product?.removeCTC ? undefined : product?.paymentErrorCallMeNow?.label,
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorUpdatePaymentMethod]);

  useEffect(() => {
    if (isSuccessUpdatePaymentMethod && handleEnableNextStep) {
      setIsSuccessChangePayment(true);
      handleEnableNextStep();
      resetPutPaymentState?.();
    }
  }, [isSuccessUpdatePaymentMethod]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PAYMENT_METHOD);
    setIsPreselectedCard(true);
    setIsLastCard(true);
    return () => {
      setIsLastCard(false);
    };
  }, []);

  return (
    <>
      <TaggingPaymentMethodCard />
      <PaymentMethod
        owningIndividual={owningData.owningIndividual}
        handleChangePayment={onChangePayment}
        handleGoPrevSlide={handleGoPrevSlide}
        payMean={selectedPaymean}
        showLoader={!isSuccessChangePayment}
        config={{
          title,
          subtitle,
          buttonLabel,
          titlePaymentError,
          messagePaymentError,
          yourIbanTitle,
          yourCreditCardTitle,
          yourDebitCardTitle,
          yourPrepaidCardTitle,
          yourPaypalTitle,
          expirationLabelTitle,
        }}
      />
    </>
  );
};

export default PaymentMethodCard;

