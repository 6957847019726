import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  CONSUMER_CMS_SHOPPING_CART,
  errorMock,
  ICommonLabelsInStepper,
  IGenericErrorCMS,
  IGlobalConfigCMS,
  IPayments,
  organizeCommonLabelsInStepper,
  productSpecificError,
  SELECTED_PAYMENT,
  SELECTED_UPFRONT_PAYMENT,
  useCmsConfig,
  useGetShoppingCartSilentUpdate,
  useUPCAutomaticRecharge,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey, openPopup, setTrackLink } from '@vfit/shared/data-access';
import {
  EMethodCode,
  ICMSError,
  ICommonData,
  IOptionalText,
  ISIAFlowType,
  IUpdateProductPriceType,
} from '@vfit/shared/models';
import { resetData, useTracking } from '@vfit/shared/data-access';
import { CheckboxCards, PaymentMethod } from '@vfit/consumer/components';
import {
  DEFAULT_CREDIT_CARD,
  isUpfrontNewMopEnabled,
  organizePaymentMethod,
  organizePaymentMethodError,
  organizeUpfrontSelectionCard,
} from '../upfrontPaymentCard.utils';
import { useCheckout } from '../../../../iBuyMobile.context';
import { ID_FLOWS } from '../../../checkout.constants';
import { getUserInfo, getTagging } from '../../../checkout.utils';
import UpfrontPaymentSubmitCard from '../UpfrontPaymentSubmitCard/upfrontPaymentSubmitCard';
import { updateProductPrice } from '../../../../ShoppingCart/shoppingCart.utils';
import { usePaymentAuthorizeFlow } from '../../../hooks/UsePaymentAuthorizeFlow/usePaymentAuthorizeFlow';

const UpfrontPayment = ({ handleOnChangeEnableGoNext, handleGoPrevSlide }: ICommonData) => {
  const queryClient = useQueryClient();
  const goNextButton = document.getElementById('sticky-offer-next');
  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PAYMENTS_MOBILE
  ) as IPayments;
  const commonLabels = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_STEPPER_COMMON_LABELS_MOBILE
  ) as ICommonLabelsInStepper;
  const cmsGlobalConfig = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;

  const { goBackInStepper } = organizeCommonLabelsInStepper(commonLabels);

  const { buttonLabel } = organizePaymentMethod(dataFromCms);
  const {
    titlePaymentError,
    messagePaymentError,
    buttonPaymentError,
    titleErrorErrorRetriveCart,
    messageErrorRetriveCart,
    titleErrorUpdatePaymentRecharge,
    messageErrorUpdatePaymentRecharge,
  } = organizePaymentMethodError(dataFromCms);
  const {
    titleUpFront,
    descriptionCC,
    descriptionDC,
    descriptionPrepaid,
    descriptionPaypal,
    titleCC,
    titleDC,
    titlePrepaid,
    titlePaypal,
    descriptionCreditRemaning,
    expirationLabelTitle,
    paymentMethod,
    bottomModalConfig,
  } = organizeUpfrontSelectionCard(dataFromCms, cmsGlobalConfig);
  const [isLoadingCreditCardPayment, setIsLoadingCreditCardPayment] = useState(false);
  const {
    checkoutErrors,
    owningData,
    recharge,
    upfrontPayment,
    availablePaymentMethods,
    isModalButtonSticky,
    product,
    setProduct,
    setHideStickyBar,
    setIsDisabledBackButton,
    setIsLastCard,
    setCurrentStepKey,
    setUpdateProductPriceCtrl,
    setIsLoadingGoNext,
  } = useCheckout();

  const selectedPayment = localStorage.getItem(SELECTED_PAYMENT);

  const prevRecurrentPaymentMethod = paymentMethod.find(
    (el) => el.value?.toLowerCase() === selectedPayment?.toLowerCase()
  );

  const itemToBeConfirmed = isUpfrontNewMopEnabled(cmsGlobalConfig, prevRecurrentPaymentMethod);

  const [selectedItem, setSelectedItem] = useState<IOptionalText | undefined>(
    prevRecurrentPaymentMethod
  );
  const shoppingCart = getFromLocalStorageByKey('shoppingCart');
  const cartId = shoppingCart?.id;

  const isNotIbanOrWalletPayment = availablePaymentMethods?.find(
    (availablePayment) =>
      availablePayment?.configured &&
      availablePayment?.methodCode !== EMethodCode.BANK_ACCOUNT &&
      availablePayment?.methodCode !== EMethodCode.WALLET
  );

  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;

  const taggingMobile = getTagging('checkout', 'mobile', queryClient);
  const { trackView } = useTracking({
    event: ['checkout_step10.1'],
    event_label: 'upfront payment',
    opts: {
      ...taggingMobile?.opts,
      ...(selectedPayment && { payment_method: selectedPayment }),
    },
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'upfront payment');

  useEffect(() => {
    const trackGoNext = () => {
      let event_label: string;

      if (selectedItem?.value === 'creditCard') {
        event_label = 'Credit Card';
      } else if (selectedItem?.value === 'creditCardAndPaypal') {
        event_label = 'Paypal';
      } else if (selectedItem?.value === 'debitCard') {
        event_label = 'Debit Card';
      } else if (selectedItem?.value === 'prepaidCard') {
        event_label = 'Prepaid Card';
      } else {
        event_label = '';
      }

      trackView({
        event_name: 'ui_interaction',
        event_label_track: event_label,
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
    };
    if (selectedItem?.value)
      localStorage.setItem(
        SELECTED_UPFRONT_PAYMENT,
        selectedItem?.value?.toLowerCase() === 'creditcardandpaypal'
          ? EMethodCode.PAYPAL
          : selectedItem?.value
      );
    goNextButton?.addEventListener('click', trackGoNext);

    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, [selectedItem]);

  const {
    mutate: mutateUPC,
    isLoading: isLoadingUPC,
    isSuccess: isSuccessUPC,
    isError: isErrorUPC,
    error: errorUPC,
  } = useUPCAutomaticRecharge();

  const {
    error: errorGetShoppingCart,
    isSuccess: isSuccessShoppingCart,
    isLoading: isLoadingShoppingCart,
    isError: isErrorShoppingCart,
    refetch: refetchShoppingCart,
  } = useGetShoppingCartSilentUpdate(cartId, { enabled: false });

  const {
    isError: authorizeIsError,
    isSuccess: authorizeIsSuccess,
    isLoading: authorizeIsLoading,
    payMeanIsError,
    paymentIsError,
  } = usePaymentAuthorizeFlow(
    selectedItem?.value,
    ISIAFlowType.INSTANT,
    itemToBeConfirmed ? 'verify' : undefined
  );

  const resetLoaders = () => {
    setHideStickyBar(false);
    setIsDisabledBackButton(false);
    setIsLoadingCreditCardPayment(false);
  };

  const errorOnSubmit = (foundedError?: ICMSError) => {
    resetLoaders();
    const { buttonPaymentError, urlPaymentErrorCallMeNow } = productSpecificError(product);
    checkoutErrors?.showAndTrackError?.(
      genericError,
      () => {
        return;
      },
      undefined,
      foundedError?.title || '',
      foundedError?.message || '',
      () => {
        return;
      },
      'Chiudi',
      foundedError?.isClickToCall ? () => () => openPopup(urlPaymentErrorCallMeNow) : undefined,
      foundedError?.isClickToCall && !product?.removeCTC ? buttonPaymentError : undefined,
      isModalButtonSticky,
      true
    );
  };

  const startPaymentForCreditCard = (event: Event) => {
    event.stopPropagation();
    setHideStickyBar(true);
    setIsDisabledBackButton(true);
    setIsLoadingCreditCardPayment(true);
  };

  const getDynamicSubtitle = useCallback(() => {
    const actualMethodType = upfrontPayment?.type?.toLowerCase();
    switch (actualMethodType) {
      case EMethodCode.CREDIT_CARD?.toLowerCase():
        return descriptionCC;
      case EMethodCode.DEBIT_CARD?.toLowerCase():
        return descriptionDC;
      case EMethodCode.PREPAID_CARD?.toLowerCase():
        return descriptionPrepaid;
      case EMethodCode.PAYPAL?.toLowerCase():
        return descriptionPaypal;
      case EMethodCode.BANK_ACCOUNT?.toLowerCase():
      case EMethodCode.WALLET?.toLowerCase():
      default:
        return actualMethodType || '';
    }
  }, [upfrontPayment]);

  useEffect(() => {
    const resetPaymentData = () => {
      resetData(queryClient, ['payMean', 'authorize']);
    };
    if (authorizeIsError) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      resetPaymentData();
      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        errorMock('authorize', { url: API.AUTHORIZE, status: '500', statusText: '500' }),
        titlePaymentError,
        messagePaymentError,
        action,
        buttonPaymentError || 'Chiudi',
        () => openPopup(product?.genericErrorCallMeNow?.url || '', action),
        product?.removeCTC ? undefined : product?.paymentErrorCallMeNow?.label,
        isModalButtonSticky,
        true
      );
    }
    if (paymentIsError || payMeanIsError) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      resetPaymentData();
      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        paymentIsError
          ? errorMock('payment', { url: API.PAYMENT, status: '500', statusText: '500' })
          : errorMock('paymean', { url: API.PAYMEAN, status: '500', statusText: '500' }),
        titlePaymentError,
        messagePaymentError,
        action,
        buttonPaymentError || 'Chiudi',
        () => openPopup(product?.genericErrorCallMeNow?.url || '', action),
        product?.removeCTC ? undefined : product?.paymentErrorCallMeNow?.label,
        isModalButtonSticky,
        true
      );
    }
  }, [authorizeIsError, payMeanIsError, paymentIsError]);

  useEffect(() => {
    if (isSuccessShoppingCart) {
      setProduct({
        ...product,
        ...updateProductPrice(product, IUpdateProductPriceType.EDIT),
      });
    }
  }, [isSuccessShoppingCart]);

  useEffect(() => {
    setIsLoadingGoNext(authorizeIsLoading);

    if (!authorizeIsLoading) handleOnChangeEnableGoNext?.(authorizeIsSuccess);
  }, [authorizeIsSuccess, authorizeIsLoading]);

  useEffect(() => {
    if (!isLoadingShoppingCart && isErrorShoppingCart) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {
          handleGoPrevSlide?.();
        },
        errorMock(
          'shoppingCart',
          errorGetShoppingCart,
          undefined,
          undefined,
          'get shopping cart in upfront payment',
          true,
          {
            selectedPayment: selectedPayment || '',
            availablePaymentMethods: availablePaymentMethods || 'no payments',
          }
        ),
        titleErrorErrorRetriveCart,
        messageErrorRetriveCart,
        () => {
          handleGoPrevSlide?.();
        },
        goBackInStepper || 'Torna indietro',
        undefined,
        undefined,
        isModalButtonSticky,
        true
      );
    }
  }, [isLoadingShoppingCart, isErrorShoppingCart]);

  useEffect(() => {
    if (!isLoadingUPC && isErrorUPC) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {
          handleGoPrevSlide?.();
        },
        errorMock('updateProductCharacteristics', errorUPC),
        titleErrorUpdatePaymentRecharge,
        messageErrorUpdatePaymentRecharge,
        () => {
          handleGoPrevSlide?.();
        },
        goBackInStepper || 'Torna indietro',
        undefined,
        undefined,
        isModalButtonSticky,
        true
      );
    } else if (!isLoadingUPC && isSuccessUPC && cartId) {
      refetchShoppingCart();
    }
  }, [isLoadingUPC, isErrorUPC, isSuccessUPC]);

  useEffect(() => {
    setIsDisabledBackButton(isLoadingUPC || isLoadingShoppingCart);
  }, [isLoadingUPC, isLoadingShoppingCart]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.UPFRONT_PAYMENT);
    resetData(queryClient, ['getShoppingCart']);
    // reset for back SIA
    window.history.pushState(null, '', window.location.pathname);
    setUpdateProductPriceCtrl({
      isPriceUpdated: true,
      isUpdatePrice: false,
    });
    mutateUPC(recharge.option || 'No');
    setIsLastCard(true);
    if (isNotIbanOrWalletPayment && itemToBeConfirmed) {
      goNextButton?.addEventListener('click', startPaymentForCreditCard);
      localStorage.setItem(SELECTED_UPFRONT_PAYMENT, EMethodCode.CREDIT_CARD);
    }
    return () => {
      goNextButton?.removeEventListener('click', startPaymentForCreditCard);
      setIsLastCard(false);
    };
  }, []);

  return (
    <div>
      {isLoadingCreditCardPayment && <UpfrontPaymentSubmitCard onErrorRetry={errorOnSubmit} />}
      <div
        style={{ display: isLoadingCreditCardPayment ? 'none' : 'block', paddingBottom: '32px' }}
      >
        {itemToBeConfirmed && (
          <PaymentMethod
            owningIndividual={owningData.owningIndividual}
            handleGoPrevSlide={handleGoPrevSlide}
            payMean={upfrontPayment || DEFAULT_CREDIT_CARD}
            showLoader={authorizeIsLoading || isLoadingUPC || isLoadingShoppingCart}
            config={{
              title: titleUpFront,
              subtitle: getDynamicSubtitle(),
              buttonLabel,
              titlePaymentError,
              messagePaymentError,
              yourCreditCardTitle: titleCC,
              yourDebitCardTitle: titleDC,
              yourPrepaidCardTitle: titlePrepaid,
              yourPaypalTitle: titlePaypal,
              expirationLabelTitle,
            }}
          />
        )}
        {!itemToBeConfirmed && (
          <CheckboxCards
            title={titleUpFront}
            description={descriptionCreditRemaning}
            items={paymentMethod}
            value={false}
            setSelectedItem={setSelectedItem}
            disableItems={authorizeIsLoading}
            isSuccess={!isLoadingUPC && !isLoadingShoppingCart}
            bottomModal={bottomModalConfig}
          />
        )}
      </div>
    </div>
  );
};

export default UpfrontPayment;
