import { useMutation, useQueryClient } from 'react-query';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  errorMock,
  GetCustomerResponse,
  IFindCallerResponse,
  IShoppingCartResponse,
  retrieveProduct,
} from '@vfit/consumer/data-access';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { INotifyRequestPayload } from './notify.models';

/**
 * This method calls the service that notify the user with an email showing the offer details.
 * Method: POST
 * @param payload
 * @param customOptions
 * @returns
 */
const notifyService = (
  payload: INotifyRequestPayload,
  customOptions?: CustomOptions
): Promise<{}> =>
  nextClient.post(`${API.CARE_V1}/${API.NOTIFY}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Custom mutation hook for run createCustomer using the createCustomer, the customerData and the shoppingCart.
 * Call this after the customer and the shoppingCart have just been created.
 * @returns
 */
export const useNotify = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['notifyService'],
    () => {
      const product = retrieveProduct();
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');

      const cartId = shoppingCart?.id;
      const owningIndividualId =
        customerData?.[0]?.owningIndividual?.id || findCaller?.individualRef?.contactId;
      const email =
        customerData?.[0]?.owningIndividual?.contactMedium?.[0]?.emailAddress ||
        localStorage?.getItem('email_cus') ||
        '';
      const firstName =
        customerData?.[0]?.owningIndividual?.firstName ||
        findCaller?.individualRef?.firstName ||
        localStorage?.getItem('name_cus') ||
        '';
      const lastName =
        customerData?.[0]?.owningIndividual?.lastName ||
        findCaller?.individualRef?.lastName ||
        localStorage?.getItem('surname_cus');

      let payload: INotifyRequestPayload;
      if (!!owningIndividualId && !!shoppingCart) {
        payload = {
          template: 'sendContractSummary',
          lastName,
          firstName,
          email,
          orderId: cartId,
          contractSummaryLink: [
            `https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/cce-pdf/${
              product?.offerId || '3556298'
            }.pdf`,
          ],
          individualId: owningIndividualId,
        };
      }
      return notifyService(payload);
    },
    {
      onSuccess: (data) => {
        localStorage.setItem('notify', '1');
        queryClient.setQueryData('notify', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - notify: `, error);
        queryClient.setQueryData('notify', errorMock(`notify`, error));
        localStorage.setItem(`notify`, JSON.stringify(errorMock(`notify`, error)));
      },
    }
  );
};

