import { IPayments } from '@vfit/consumer/data-access';

export const organizePaymentMethod = (dataFromCms: IPayments) => ({
  title: dataFromCms?.paymentsmobile?.title || '',
  subtitle: dataFromCms?.paymentsmobile?.userRegistered?.description || '',
  buttonLabel: dataFromCms?.paymentsmobile?.userRegistered?.changeLabel || '',
  yourIbanTitle: dataFromCms?.paymentsmobile?.paymentMethod?.titleIban || '',
  yourCreditCardTitle: dataFromCms?.paymentsmobile?.paymentMethod?.titleCard || '',
  yourDebitCardTitle: dataFromCms?.paymentsmobile?.paymentMethod?.titleDebitCard || '',
  yourPrepaidCardTitle: dataFromCms?.paymentsmobile?.paymentMethod?.titlePrepaidCard || '',
  yourPaypalTitle: dataFromCms?.paymentsmobile?.paymentMethod?.titlePaypal || '',
  expirationLabelTitle: dataFromCms?.paymentsmobile?.paymentMethod?.expirationLabel || '',
});

export const organizePaymentMethodError = (paymentCMS: IPayments) => ({
  titlePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.title || '',
  messagePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.description || '',
  messageSelectedPaymentError:
    paymentCMS?.paymentsmobile?.paymentError?.notFound?.message ||
    'Siamo spiacenti ma si è verificato un errore sulla scelta del nuovo metodo di pagamento. Riprovare in seguito.',
  titleSelectedPaymentError: paymentCMS?.paymentsmobile?.paymentError?.notFound?.title || 'Ops!',
});

