import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import {
  CART_TYPE,
  errorMock,
  getCostFromShoppingCartByType,
  getLastPaymentMethod,
  getOneTimeAmountFromShoppingCart,
  isMobileProduct,
  retrieveProduct,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { EMethodCode, IPayMeanServiceResponse, ISIAFlowType } from '@vfit/shared/models';
import {
  IAuthorizeMutationPayload,
  IAuthorizePayload,
  IAuthorizeResponse,
} from './authorize.models';

/**
 * This method calls the Authorize Service
 * Method: POST
 * SearchParams: salesChannel: selfService, isIBANValidationRequired: 'false'
 * @param payload
 * @param cartId
 * @param customOptions
 * @returns
 */
export const authorizeService = (
  payload: IAuthorizePayload,
  cartId: string,
  customOptions?: CustomOptions
): Promise<IAuthorizeResponse> =>
  nextClient.post(`${API.PAYMENT_MANAGEMENT_ORDER}/${cartId}/${API.AUTHORIZE}`, payload, {
    searchParams: {
      ...nextClient.searchParams,
      isIBANValidationRequired: 'false',
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * This is a custom hook to manage the authorizeService.
 * @returns
 */
export const useAuthorizeMutation = () => {
  LoggerInstance.debug('HANDLE SELECT IN AUTHORIZE');
  const queryClient = useQueryClient();
  return useMutation(
    'paymentAuth',
    ({
      payMeanType: payloadPaymeanType,
      siaFlowType,
      authorizationOperation: authorizationOperationMethod,
    }: IAuthorizeMutationPayload) => {
      const product = retrieveProduct();
      const shoppingCart = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id;
      LoggerInstance.debug('HANDLE SELECT IN AUTHORIZE ENTER MUTATION CALL');
      const payMean: IPayMeanServiceResponse = getFromLocalStorageByKey('payMean');
      // const paymentData: IPaymentServiceResponse = getFromLocalStorageByKey('paymentData');
      const recurrence: string = retrieveProduct()?.recurrence;
      const offerId: string = product?.offerId?.toString();
      const lastPayment = getLastPaymentMethod(payMean);
      const recurringAmount =
        getCostFromShoppingCartByType(CART_TYPE.RECURRING)?.formattedPrice?.toString() || 0;
      const activationCost = getOneTimeAmountFromShoppingCart();
      const shippingCost = getCostFromShoppingCartByType(CART_TYPE.SHIPPING);
      let totalPrice = activationCost.price || 0;
      if (shippingCost.price) totalPrice += shippingCost.price;

      let authorizationOperation = authorizationOperationMethod;
      if (!authorizationOperation)
        authorizationOperation = payloadPaymeanType ? 'capture' : 'verify';

      const price =
        product.isLockInProduct || product.isLockInMMProduct
          ? `${recurringAmount}€`
          : retrieveProduct()?.price;
      // "27.90&euro;mese,3893198";
      const formattedValue = price
        .trim()
        .toString()
        .replace(',', '.')
        .replace('/', ',')
        .replace('€', '&euro;');
      const payMeanType = payloadPaymeanType || lastPayment?.type || undefined;
      const payMeanId =
        payMean && authorizationOperation === 'verify' ? lastPayment?.id : undefined;
      const ordDescr = totalPrice
        ? formattedValue.concat(`,${recurrence},${offerId},${totalPrice}&euro;`)
        : formattedValue.concat(`,${recurrence},${offerId},`);
      const payMeanPurpose = siaFlowType === ISIAFlowType.RECURRENT ? 'onBill' : 'immediate';

      const isFlowTypeSunriseMobileCop = () => {
        const isPaypalPayment = payMeanType
          ?.toString()
          ?.toLowerCase()
          ?.includes(EMethodCode.PAYPAL?.toString()?.toLowerCase());
        if (
          (product?.enableRecurringPayment && !isPaypalPayment) ||
          (product?.enableRecurringPayment && isPaypalPayment && payMeanPurpose === 'immediate')
        )
          return true;
        return false;
      };

      // OLD FLOW TYPE: isMobileProduct() || product?.enableRecurringPayment
      let flowType: 'cop' | 'Sunrise_Mobile_Cop';
      if (!isMobileProduct()) {
        if (isFlowTypeSunriseMobileCop()) {
          flowType = 'Sunrise_Mobile_Cop';
        } else {
          flowType = 'cop';
        }
      } else {
        flowType = 'Sunrise_Mobile_Cop';
      }

      const payload: IAuthorizePayload = {
        payMeanType,
        payMeanPurpose,
        authorizationOperation,
        flowType,
        orddescr: ordDescr,
        payMeanId,
      };

      LoggerInstance.debug('NEW PAYMENT CARD PAYLOAD : ', payload);
      return authorizeService(payload, cartId);
    },
    {
      onSuccess: (data: IAuthorizeResponse) => {
        localStorage.setItem('authorize', JSON.stringify(data));
        LoggerInstance.debug('authorize SUCCESS', data);
        queryClient.setQueryData('authorize', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - authorize: `, error);
        queryClient.setQueryData('authorize', errorMock(`authorize`, error));
        localStorage.setItem(`authorize`, JSON.stringify(errorMock(`authorize`, error)));
      },
    }
  );
};

/**
 * Authorize Query saves the complete authorize response
 */
export const useGetAuthorize = () => useQuery<IAuthorizeResponse>('authorize');
