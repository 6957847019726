import {
  CONSUMER_CMS_SHOPPING_CART,
  ErrorCodes,
  getCurrentUserType,
  getCustomerIdentification,
  GetCustomerResponse,
  IAddonCMS,
  IContactMediumCustomer,
  IOwningIndividual,
  IPayments,
  IProduct,
  IShoppingCartResponse,
  IUserType,
  LOGGED_USER_KEYS,
  retrieveCartCookie,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import {
  API,
  CartProduct,
  ITrackError,
  ITrackErrorRes,
  NEXT_ERR_MSG,
  NEXT_ERR_TRANSACTION_ID,
  PageProduct,
  TEventName,
  TOptions,
  VisitorTrackingOpts,
} from '@vfit/shared/data-access';
import { removeCurrency } from '@vfit/consumer/hooks';
import { AppMobile, checkWindow, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { ICMSError, ICountryObject, IFormat, IGenericError, IStepCard } from '@vfit/shared/models';
import crypto from 'crypto-es';
import { IEditorialErrorConfig } from './checkout.models';
import { DEFAULT_DATA } from '../iBuyMobile.context.data';
import {
  ADDON_FLOW,
  COMPONENTS_FLOW,
  MOBILE_LINE_APP_FLOW,
  MOBILE_LINE_FLOW,
} from './checkout.flow';
import { DEFAULT_CURRENT_STEP_KEY, ID_FLOWS } from './checkout.constants';
import { getSerializeContext, retrieveAddons, retrieveProduct } from '../iBuyMobile.utils';
import { SoftManagerService } from '@vfit/consumer/providers';
import { QueryClient } from 'react-query';

// region ERROR UTILS

const handleCRMErrorCodes = (backendError: string): string => {
  switch (backendError) {
    case 'CRM-0020':
    case 'CRM-0024':
      return ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT;
    case 'CRM-0021':
    case 'CRM-0023':
      return ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT;
    case 'CRM-0022':
    case 'CRM-0025':
      return ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT;
    default:
      return '';
  }
};

// endregion

// region UTILS

const isEsim = (selectedSimOption: IFormat | undefined) => {
  return selectedSimOption?.value?.trim()?.toLowerCase()?.includes('esim') || false;
};

const isErrorOnPaymentGeneric = (): boolean => {
  if (!checkWindow()) return false;
  const queryString = window?.location?.search;
  return queryString.includes(ErrorCodes.ERROR_ON_PAYMENT);
};

const isErrorOnInstant = (): boolean => {
  if (!checkWindow()) return false;
  const queryString = window?.location?.search;
  return queryString.includes(ErrorCodes.MODE_BACK_SIA_INSTANT);
};

const isDoneOnRecurrent = (): boolean => {
  if (!checkWindow()) return false;
  const queryString = window?.location?.search;
  return queryString.includes(ErrorCodes.MODE_DONE_SIA_RECURRENT);
};

const isEnabledVoucher = (product: IProduct): boolean => product.enableVoucher || false;

const getActiveSlideFromStorage = () => {
  const ctx = getSerializeContext();
  if (!ctx) return false;
  if (isDoneOnRecurrent()) {
    return ID_FLOWS.UPFRONT_PAYMENT;
  }
  return ctx?.currentStepKey || DEFAULT_CURRENT_STEP_KEY;
};

const prependStep = (flowCards: IStepCard[], stepToPrepend: IStepCard[]): IStepCard[] =>
  stepToPrepend.concat(flowCards);

const organizeFlowsAppend = (
  flowCards: IStepCard[],
  enableAppend: boolean,
  idFromStep: string,
  appendSteps: IStepCard[]
) => {
  const indexAppend = flowCards.findIndex((el) => el.title === idFromStep);
  let organizedFlows = flowCards.slice(0, indexAppend + 1);
  const lastFlows = flowCards.slice(indexAppend + 1);
  if (enableAppend) {
    const alreadyPresent = appendSteps[0].title === lastFlows[0].title;
    if (alreadyPresent) return flowCards;
    organizedFlows = organizedFlows.concat(appendSteps);
    organizedFlows = organizedFlows.concat(lastFlows);
  } else if (lastFlows[0].title === appendSteps[0].title) {
    const removedAppendInfo = lastFlows.slice(appendSteps.length);
    organizedFlows = organizedFlows.concat(removedAppendInfo);
  } else if (appendSteps?.findIndex((aS) => aS.title === lastFlows[0].title) > -1) {
    const removedAppendInfo = lastFlows.slice(1);
    organizedFlows = organizedFlows.concat(removedAppendInfo);
  } else {
    organizedFlows = organizedFlows.concat(lastFlows);
  }
  return organizedFlows;
};

const retrieveOwningIndividual = (
  useCustomerData: GetCustomerResponse,
  countries: ICountryObject[] = []
) => {
  const owningIndividual = useCustomerData?.owningIndividual;
  const identification = getCustomerIdentification(owningIndividual as any);

  if (!owningIndividual) {
    return DEFAULT_DATA.owningIndividual;
  }

  const nationality = countries?.filter(
    (element: any) => element.id === identification?.nationality
  );

  const formatDate = (date: Date) => {
    const retrievedDate = date.toString();

    const year = retrievedDate.substring(0, 4);
    const month = retrievedDate.substring(5, 7);
    const day = retrievedDate.substring(8);

    return day.concat('/', month, '/', year);
  };

  let identificationType = '';
  const expirationDate = identification?.expirationDate
    ? formatDate(identification.expirationDate as any)
    : '';

  if (identification?.identificationType === '1') identificationType = "Carta d'identità";
  else if (identification?.identificationType === '2') identificationType = 'Passaporto';
  else if (identification?.identificationType === '3') identificationType = 'Patente di guida';

  const owningIndividualToReturn: IOwningIndividual = {
    nation: owningIndividual?.nation || '',
    firstName: owningIndividual?.firstName || '',
    lastName: owningIndividual?.lastName || '',
    fiscalCode: owningIndividual?.fiscalCode || '',
    identification: [
      {
        identificationType,
        nationality: nationality?.[0]?.displayName || '',
        identificationNumber: identification?.identificationNumber || '',
        expirationDate,
      },
    ],
    contactMedium: [owningIndividual?.contactMedium[0], owningIndividual?.contactMedium[1]],
  };

  return owningIndividualToReturn;
};

const checkErrorCustomer = (
  errors: ICMSError[],
  errorCode: string,
  erroMessage?: IGenericError
): IEditorialErrorConfig => {
  let error = {
    title: erroMessage?.title || '',
    message: erroMessage?.description || '',
    actionText: erroMessage?.buttonLabel || '',
    url: '',
  };

  const getSpecificError = (
    errorCodeLocal: string,
    defaultTitle?: string,
    defaultMessage?: string
  ) => {
    const specificError = errors?.find((item: ICMSError) => item.error === errorCodeLocal);
    return {
      title: specificError?.title || defaultTitle || '',
      message: specificError?.message || defaultMessage || '',
      actionText: specificError?.button?.title || '',
      url: specificError?.button?.urlForRedirect || '',
      isPopup: specificError?.isClickToCall === 'true',
      disableTrack: true,
    };
  };

  if (errorCode === ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT) {
    error = getSpecificError(
      ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT,
      'Attenzione',
      'Il numero di telefono inserito è già esistente'
    );
  }
  if (errorCode === ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT) {
    error = getSpecificError(
      ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT,
      'Attenzione',
      "L'e-mail inserita è già esistente"
    );
  }
  if (errorCode === ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT) {
    error = getSpecificError(
      ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT,
      'Attenzione',
      'I dati di contatto inseriti sono associati ad un altro utente'
    );
  }

  if (errorCode === ErrorCodes.CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT) {
    error = getSpecificError(
      ErrorCodes.CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT,
      'Attenzione',
      'Esiste già un utente con questo codice fiscale.'
    );
  }
  if (errorCode === ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT) {
    error = getSpecificError(
      ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT,
      'Attenzione',
      'Il documento inserito è già presente'
    );
  }
  if (errorCode === ErrorCodes.CREATE_CUSTOMER_MISSING_NATION_ADDRESS) {
    error = getSpecificError(
      ErrorCodes.CREATE_CUSTOMER_MISSING_NATION_ADDRESS,
      'Attenzione',
      "L'indirizzo selezionato non è disponibile. Si prega di riprovare più tardi"
    );
  }
  if (errorCode === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED) {
    error = getSpecificError(
      ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED,
      'Attenzione',
      'Questa offerta non è compatibile per questa tipologia di utente'
    );
  }

  return error;
};

const organizePaymentError = (dataFromCms: IPayments) => ({
  titleErrorPayment: dataFromCms?.paymentsmobile?.paymentError?.title || '',
  messageErrorPayment: dataFromCms?.paymentsmobile?.paymentError?.description || '',
  actionTextErrorPayment: dataFromCms?.paymentsmobile?.paymentError?.buttonLabel || '',
});

const organizeOrderNotCompletedError = (dataFromCms: IPayments) => ({
  titleOrderError: dataFromCms?.paymentsmobile?.orderNotCompletedError?.title || '',
  messageOrderError: dataFromCms?.paymentsmobile?.orderNotCompletedError?.description || '',
  actionTextOrderError: dataFromCms?.paymentsmobile?.orderNotCompletedError?.buttonLabel || '',
});

const addRemoveVisibilityCards = (type: 'hide' | 'show') => {
  const cardsEl: HTMLElement | null = document.getElementById('stepper-cards-container');
  const footerEl: HTMLElement | null = document.getElementById('sticky-offer-stepper');
  if (cardsEl && footerEl) {
    if (type === 'hide') {
      cardsEl.style.visibility = 'hidden';
      footerEl.style.visibility = 'hidden';
    } else {
      cardsEl.style.visibility = 'inherit';
      footerEl.style.visibility = 'inherit';
    }
  }
};

/**
 * Return slide index by key
 * @param flows
 * @param key
 */
const getSlideIndexByKey = (flows: IStepCard[], key: string): number =>
  flows.findIndex((f) => f.title === key);

// endregion

// region TAGGING
const getTagging = (pageType: string, type = 'mobile', queryClient?: QueryClient) => {
  const product = retrieveProduct();
  const addons = retrieveAddons();
  const shoppingCart = getFromLocalStorageByKey('shoppingCart');
  const isCheckOrder = window?.location?.href?.includes('checkOrder');
  const isWinBack = product?.isWinback || product?.isWinbackLegacy;
  let cartWithAddons;
  if (product && queryClient && addons) {
    const { getCartProductsWithAddon, retrieveCartAddons } = SoftManagerService(queryClient);
    cartWithAddons = getCartProductsWithAddon(product, retrieveCartAddons(addons, 'mobile'));
  }

  const trackingProduct: CartProduct = {
    cart_id: (shoppingCart as IShoppingCartResponse)?.id,
    cart_product_id: `${product?.offerId}`,
    cart_product_name: product?.slug,
    cart_product_category: type,
    cart_product_price: product?.price && removeCurrency(product.price),
    cart_product_quantity: '1',
    cart_total: product?.price && removeCurrency(product.price),
    ...(cartWithAddons && cartWithAddons),
  };

  const opts: TOptions = {
    event_label: pageType,
    product_target_segment: 'consumer',
    event_category: 'sales',
    page_subsection: 'checkout',
    page_section: isWinBack ? 'campaign' : `${type} offers`,
    page_type: isCheckOrder ? 'order_loading' : pageType,
    journey_type: 'journey',
    journey_name: isWinBack ? 'winback' : 'checkout',
  };

  const pageProductsInfo: PageProduct = {
    product_name: product?.slug || '',
    product_price: product?.price ? removeCurrency(product?.price) : '',
    product_id: `${product?.offerId}`,
    product_quantity: '1',
    product_category: type,
  };

  return {
    trackingProduct,
    opts,
    pageProductsInfo,
  };
};

const getUserInfo = (): VisitorTrackingOpts => {
  const { SHA256 } = crypto;
  const user = getFromLocalStorageByKey('user');
  const silentLogin: any = getFromLocalStorageByKey('silentLogin');
  const loginEnrichement: any = getFromLocalStorageByKey('loginEnriched');
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;
  const customerData: any = getFromLocalStorageByKey('customerData');
  const appSessionFromStorage: any = checkWindow() && localStorage.getItem('appSession');
  const appSession: any = !!appSessionFromStorage && JSON.parse(appSessionFromStorage);
  const appMsisdn: string = appSession?.value;
  const visitorLoginStatus = appMsisdn || isLoggedUser ? 'logged in' : 'logged out';

  return {
    visitor_login_type: 'web',
    ...(!!retrieveCartCookie() && { visitor_type: 'returning visitor' }),
    ...(user?.customer?.[0].id && { visitor_customer_id: user?.customer[0].id }),
    ...(user?.taggedInfoCust?.email && {
      visitor_id_email: SHA256(user?.taggedInfoCust?.email).toString(),
    }),
    ...(user?.customer?.[0].msisdns && {
      visitor_id_asset_list: user?.customer?.[0].msisdns?.map(
        (msisdn: string) => SHA256(msisdn)?.toString() || ''
      ),
    }),
    ...(loginEnrichement &&
      loginEnrichement.items[0]?.value && {
        visitor_id_asset_active: SHA256(loginEnrichement.items[0]?.value).toString(),
      }),
    ...(appMsisdn && { visitor_id_asset_active: SHA256(appMsisdn).toString() }),
    ...(visitorLoginStatus && { visitor_login_status: visitorLoginStatus }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.birthDate && {
        visitor_customer_dob: customerData[0]?.owningIndividual?.birthDate,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.gender && {
        visitor_customer_gender: customerData[0]?.owningIndividual?.gender,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.city && {
        visitor_customer_location_city: customerData[0]?.billingAddress?.city,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.nation && {
        visitor_customer_location_country: customerData[0]?.owningIndividual?.nation,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.postalCode && {
        visitor_customer_location_id: customerData[0]?.billingAddress?.postalCode,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.firstName && {
        visitor_customer_name_first: customerData[0]?.owningIndividual?.firstName,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.lastName && {
        visitor_customer_name_last: customerData[0]?.owningIndividual?.lastName,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.stateOrProvince && {
        visitor_customer_region: customerData[0]?.billingAddress?.stateOrProvince,
      }),
  };
};

// endregion

// region utils FLOWS

/**
 * Return correct user flow by offer
 * @param product
 */
const getUserFlow = (product?: IProduct): IStepCard[] => {
  if (!product) return [];

  const includeAddonStep = (product: IProduct): boolean => {
    const addonListCMS = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_ADDONS) as IAddonCMS;
    if (addonListCMS) {
      return (
        product?.addonList?.some((el) =>
          Object.keys(addonListCMS || {}).some((key) => key === el)
        ) ?? false
      );
    } else return false;
  };

  const mobileFlows = () => {
    let flowAppReturn = MOBILE_LINE_APP_FLOW.DEFAULT;
    if (product?.skipMnp && !product?.isEsimOnly) flowAppReturn = MOBILE_LINE_APP_FLOW.SKIP_MNP;
    if (!product?.skipMnp && product?.isEsimOnly) flowAppReturn = MOBILE_LINE_APP_FLOW.ESIM_ONLY;
    if (product?.skipMnp && product?.isEsimOnly)
      flowAppReturn = MOBILE_LINE_APP_FLOW.ESIM_ONLY_SKIP_MNP;
    if (product?.isRequiredPortability) flowAppReturn = MOBILE_LINE_APP_FLOW.PORTABILITY_REQUIRED;
    // If not have voucher card remove it from the flow
    if (!isEnabledVoucher(product)) {
      flowAppReturn = flowAppReturn.filter((el) => el.title !== ID_FLOWS.VOUCHER);
    }
    if (includeAddonStep(product)) {
      flowAppReturn = prependStep(flowAppReturn, ADDON_FLOW.ADDONS);
    }
    return flowAppReturn;
  };

  const webFlows = () => {
    let flowReturn = MOBILE_LINE_FLOW.DEFAULT;
    if (product?.skipMnp && !product?.isEsimOnly) flowReturn = MOBILE_LINE_FLOW.SKIP_MNP;
    if (!product?.skipMnp && product?.isEsimOnly) flowReturn = MOBILE_LINE_FLOW.ESIM_ONLY;
    if (product?.skipMnp && product?.isEsimOnly) flowReturn = MOBILE_LINE_FLOW.ESIM_ONLY_SKIP_MNP;
    if (product?.isWinback) flowReturn = MOBILE_LINE_FLOW.WINBACK;
    if (product?.isWinbackLegacy) flowReturn = MOBILE_LINE_FLOW.WINBACK_LEGACY;
    if (product?.isRequiredPortability) flowReturn = MOBILE_LINE_FLOW.PORTABILITY_REQUIRED;
    // If not have voucher card remove it from the flow
    if (!isEnabledVoucher(product)) {
      flowReturn = flowReturn.filter((el) => el.title !== ID_FLOWS.VOUCHER);
    }
    if (includeAddonStep(product)) {
      flowReturn = prependStep(flowReturn, ADDON_FLOW.ADDONS);
    }
    return flowReturn;
  };

  if (AppMobile.checkIsApp()) {
    const appSession = getFromLocalStorageByKey('appSession');
    if (!appSession) {
      return webFlows();
    }
    return mobileFlows();
  }
  return webFlows();
};

/**
 * Method to serializeAllSteps on finish flow
 * @param steps
 */
const seriealizeAllSteps = (steps: IStepCard[]) => {
  const serializedSteps = steps.map((s) => s.title);
  localStorage.setItem('sstps', JSON.stringify(serializedSteps));
};

/**
 * Method to de-serielize all steps on finish flow
 */
const deSeriealizeAllSteps = (product?: IProduct): IStepCard[] => {
  if (!product) return [];
  const serializedSteps: string[] | null = getFromLocalStorageByKey('sstps');
  const removeStepById = (allSteps: any, key: string) =>
    allSteps?.filter((el: any) => el?.title !== key) || allSteps || [];
  let allMobileFlows;
  if (!serializedSteps) {
    allMobileFlows = MOBILE_LINE_FLOW.DEFAULT;
    if (!isEnabledVoucher(product)) {
      allMobileFlows = removeStepById(allMobileFlows, ID_FLOWS.VOUCHER);
    }
    return allMobileFlows;
  }
  allMobileFlows =
    serializedSteps?.map((title) => COMPONENTS_FLOW.find((el) => el.title === title) || {}) || [];
  if (!isEnabledVoucher(product)) {
    allMobileFlows = removeStepById(allMobileFlows, ID_FLOWS.VOUCHER);
  }
  return allMobileFlows;
};

/**
 * Check Post delivery address isset
 */
const checkShippingAddressIsIsset = (): boolean => {
  const postShipping = getFromLocalStorageByKey('postDeliveryServiceDetails');
  return !!postShipping;
};

const getShippingForLoggedOrActive = (
  customer: GetCustomerResponse
): IContactMediumCustomer | undefined => {
  const userType = getCurrentUserType();
  if (!(userType === IUserType.LOGGED_USER || userType === IUserType.NEXT_USER_ACTIVE))
    return undefined;
  if (!customer) return undefined;
  const contactMedium = customer?.owningIndividual?.contactMedium || [];
  return contactMedium?.find((contact) => contact.type == 'postalAddress');
};

const getBillingForLoggedOrActive = (
  customer: GetCustomerResponse
): IContactMediumCustomer | undefined => {
  const userType = getCurrentUserType();
  if (!(userType === IUserType.LOGGED_USER || userType === IUserType.NEXT_USER_ACTIVE))
    return undefined;
  if (!customer) return undefined;
  return customer?.billingAddress as IContactMediumCustomer;
};

const getTrackError = (
  error: ITrackError,
  step?: string,
  queryClient?: QueryClient
): ITrackErrorRes => {
  const product: IProduct | null = retrieveProduct();
  const pageError = [
    error?.error?.url || '',
    error?.error?.status || '',
    error?.errorType || '',
    error?.errorCode || '',
    error?.isBlocking ? 'blk' : 'nblk',
    step || '',
  ]
    .filter(Boolean)
    .join('_');
  const lastDexTransaction = localStorage?.getItem(NEXT_ERR_TRANSACTION_ID) || '';
  const lastErrorMessage = localStorage?.getItem(NEXT_ERR_MSG) || '';
  const transaction_id = error?.transaction_id || lastDexTransaction;
  const err_errorMessage = error?.err_errorMessage || lastErrorMessage;
  const cartProduct = getTagging('', 'mobile', queryClient).trackingProduct;
  const userType = getCurrentUserType();
  return {
    flow: 'mobile',
    event_name: 'page_error' as TEventName,
    event_label_track: 'page_error',
    event_category: 'error',
    page_error: pageError,
    page_error_code: error?.errorCode || '',
    page_type: 'error page',
    slug: product?.slug || '',
    isBlocking: error?.isBlocking ? 'Y' : 'N',
    step,
    ...(product?.offerId && { offerId: product?.offerId }),
    ...(cartProduct && cartProduct),
    ...(cartProduct?.cart_id && { cart_id: cartProduct?.cart_id }),
    ...(transaction_id && { transaction_id }),
    ...(error?.error?.status && { status: error?.error?.status }),
    ...(error?.error?.url && { service_name: error?.error?.url }),
    ...(userType && { user_type: userType }),
    ...(err_errorMessage && { err_errorMessage }),
    ...(error?.err_errorCode && { err_errorCode: error?.err_errorCode }),
    ...(error?.err_backendErrorMessage && {
      err_backendErrorMessage: error?.err_backendErrorMessage,
    }),
    ...(error?.err_backendErrorCode && { err_backendErrorCode: error?.err_backendErrorCode }),
    ...(error?.err_backendSource && { err_backendSource: error?.err_backendSource }),
    ...(error?.otherInfo && { otherInfo: error.otherInfo }),
  };
};

// endregion

export {
  isEsim,
  handleCRMErrorCodes,
  isErrorOnPaymentGeneric,
  isErrorOnInstant,
  getActiveSlideFromStorage,
  organizeFlowsAppend,
  retrieveOwningIndividual,
  getTagging,
  checkErrorCustomer,
  organizePaymentError,
  organizeOrderNotCompletedError,
  addRemoveVisibilityCards,
  seriealizeAllSteps,
  getSlideIndexByKey,
  deSeriealizeAllSteps,
  getUserFlow,
  checkShippingAddressIsIsset,
  getShippingForLoggedOrActive,
  getBillingForLoggedOrActive,
  getTrackError,
  getUserInfo,
};

