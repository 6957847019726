import {
  GetCustomerResponse,
  IFindCallerResponse,
  IGlobalConfigCMS,
  IPayments,
} from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { EMethodCode, IPayMean } from '@vfit/shared/models';

export const DEFAULT_CREDIT_CARD: IPayMean = {
  displayBrand: '*********',
  object_type: '*****',
  id: '***********',
  type: '*****',
  accountNumber: '*******',
  brand: '********',
  lastFourDigits: '****',
  holderName: '*********',
  expiryMonth: '**',
  expiryYear: '****',
};

export const organizePaymentMethod = (dataFromCms: IPayments) => ({
  title: dataFromCms?.paymentsmobile?.title || '',
  subtitle: dataFromCms?.paymentsmobile?.userRegistered?.description || '',
  buttonLabel: dataFromCms?.paymentsmobile?.userRegistered?.changeLabel || '',
});

export const organizePaymentMethodError = (paymentCMS: IPayments) => ({
  titlePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.title || '',
  messagePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.description || '',
  messageSelectedPaymentError:
    paymentCMS?.paymentsmobile?.paymentError?.notFound?.message ||
    'Siamo spiacenti ma si è verificato un errore sulla scelta del nuovo metodo di pagamento. Riprovare in seguito.',
  titleSelectedPaymentError: paymentCMS?.paymentsmobile?.paymentError?.notFound?.title || 'Ops!',
  titleErrorErrorRetriveCart: paymentCMS?.paymentsmobile?.errorRetriveCart?.title || '',
  messageErrorRetriveCart: paymentCMS?.paymentsmobile?.errorRetriveCart?.description || '',
  titleErrorUpdatePaymentRecharge:
    paymentCMS?.paymentsmobile?.errorUpdatePaymentRecharge?.title || '',
  messageErrorUpdatePaymentRecharge:
    paymentCMS?.paymentsmobile?.errorUpdatePaymentRecharge?.description || '',
});

const checkNewMopEnabler = (cmsGlobalConfig?: IGlobalConfigCMS) => {
  const ENV_NEW_MOP_FROM_AWS = process.env['NEXT_PUBLIC_NEW_MOP_ENABLED'];
  const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
  const customerDataCF =
    customerData?.[0]?.owningIndividual?.fiscalCode || findCaller?.individualRef?.fiscalCode;
  const isGlobalNewMopUfrontFixedEnabled =
    cmsGlobalConfig?.globalconfig?.newMopUpfront?.enableNewMopFixed === 'true' ||
    ENV_NEW_MOP_FROM_AWS === 'TRUE' ||
    false;
  const isCustomerXBS = customerDataCF?.includes('XBS');
  return isCustomerXBS || isGlobalNewMopUfrontFixedEnabled;
};

export const organizeUpfrontSelectionCard = (
  dataFromCms: IPayments,
  cmsGlobalConfig?: IGlobalConfigCMS
) => {
  const isNewMopEnabled = checkNewMopEnabler(cmsGlobalConfig);

  const checkIfPayPal = (checkString: string) =>
    checkString?.toLowerCase() === EMethodCode.PAYPAL.toLowerCase();

  const paymentMethods = dataFromCms?.paymentsmobile?.upFrontPayment?.paymentMethod
    ?.filter((el) => {
      if (isNewMopEnabled) return true;
      const allowedCodes = [
        EMethodCode.CREDIT_CARD?.toLowerCase(),
        EMethodCode.PAYPAL?.toLowerCase(),
      ];
      return allowedCodes.includes(el?.value?.toLowerCase());
    })
    .map((el) => ({
      title: el?.title || '',
      description: el?.description || '',
      value: checkIfPayPal(el?.value) ? 'creditCardAndPaypal' : el?.value,
    }));

  return {
    titleUpFront: dataFromCms?.paymentsmobile?.upFrontPayment?.title || '',
    descriptionCC: dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionCC || '',
    descriptionDC: dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionDC || '',
    descriptionPrepaid: dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionPrepaid || '',
    descriptionPaypal: dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionPaypal || '',
    titleCC: dataFromCms?.paymentsmobile?.upFrontPayment?.titleCC || '',
    titleDC: dataFromCms?.paymentsmobile?.upFrontPayment?.titleDC || '',
    titlePrepaid: dataFromCms?.paymentsmobile?.upFrontPayment?.titlePrepaid || '',
    titlePaypal: dataFromCms?.paymentsmobile?.upFrontPayment?.titlePaypal || '',
    expirationLabelTitle: dataFromCms?.paymentsmobile?.upFrontPayment?.expirationLabelTitle || '',
    descriptionCreditRemaning:
      dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionCreditRemaining || '',
    paymentMethod: paymentMethods,
    bottomModalConfig: {
      textLink: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.textLink || '',
      titleModal: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.title || '',
      descriptionModal: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.description || '',
    },
  };
};

export const isUpfrontNewMopEnabled = (
  cmsGlobalConfig: IGlobalConfigCMS,
  selectedPayment: string | null
): boolean => {
  const isNewMopEnabled = checkNewMopEnabler(cmsGlobalConfig);

  let payMeanAlreadyPresent = false;
  if (isNewMopEnabled) {
    payMeanAlreadyPresent =
      selectedPayment?.toLowerCase() === EMethodCode.CREDIT_CARD.toLowerCase() ||
      selectedPayment?.toLowerCase() === EMethodCode.DEBIT_CARD.toLowerCase();
  } else {
    payMeanAlreadyPresent =
      selectedPayment?.toLowerCase() === EMethodCode.CREDIT_CARD.toLowerCase();
  }

  return payMeanAlreadyPresent;
};
