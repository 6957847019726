import { useEffect, useState } from 'react';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  CONSUMER_CMS_SHOPPING_CART,
  getCurrentUserType,
  ICustomerReferenceDataResponse,
  IPersonalInfoCMS,
  IUserType,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { ICommonData, ICountryObject, IPersonalDataObject } from '@vfit/shared/models';
import DocumentId from './components/DocumentId/documentId';
import { DEFAULT_CUSTOMER_REFERENCE, organizeDocument } from './documentCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';
import DocumentIdCardTagging from './documentIdCard.tagging';

const DocumentIdCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const [isValidForm, setIsValidForm] = useState(false);
  const { owningData, findCaller, setCurrentStepKey, setOwningData } = useCheckout();
  const documentIdCardCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;

  const isRecognized = owningData?.isLogged || findCaller?.customerHasActiveSubs;
  const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
  const customerReference: ICustomerReferenceDataResponse =
    getFromLocalStorageByKey('customerReference') || DEFAULT_CUSTOMER_REFERENCE;

  const onChangeData = (data: IPersonalDataObject) => {
    setOwningData(data);
  };

  const checkReadonly = (): boolean => {
    const customerData = getFromLocalStorageByKey('customerData');
    if (customerData) {
      const selectedDate = new Date(
        customerData?.[0].owningIndividual?.identification[0].expirationDate || ''
      );
      const yesterday: Date = new Date();
      if (
        (getCurrentUserType() === IUserType.NEXT_USER_ACTIVE ||
          getCurrentUserType() === IUserType.LOGGED_USER) &&
        selectedDate <= yesterday
      ) {
        return false;
      }
      if (
        (getCurrentUserType() === IUserType.NEXT_USER_ACTIVE ||
          getCurrentUserType() === IUserType.LOGGED_USER) &&
        selectedDate > yesterday
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setTimeout(() => {
      handleOnChangeEnableGoNext?.(isValidForm);
    }, 1000);
  }, [isValidForm, owningData]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(false);
    const oldCF = owningData?.owningIndividual?.fiscalCode;
    localStorage.setItem('customerDataCf', oldCF);
    localStorage.setItem('name_cus', owningData?.owningIndividual?.firstName);
    localStorage.setItem('surname_cus', owningData?.owningIndividual?.lastName);
    setCurrentStepKey(ID_FLOWS.DOCUMENT_ID);
  }, []);

  return (
    <>
      <DocumentIdCardTagging />
      <DocumentId
        configCms={{
          ...organizeDocument(documentIdCardCMS, isRecognized),
        }}
        data={owningData}
        onChangeData={onChangeData}
        identificationType={
          customerReference?.identificationType?.map((item: any) => item.displayName) || ['']
        }
        nationality={(countries as ICountryObject[])?.map((item: any) => item.displayName) || ['']}
        isLoading={false}
        setIsValidForm={(valid: boolean) => setIsValidForm(valid)}
        isReadonly={checkReadonly()}
      />
    </>
  );
};

export default DocumentIdCard;

