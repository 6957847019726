import { useEffect } from 'react';
import {
  API,
  CONSUMER_CMS_SHOPPING_CART,
  IGlobalConfigCMS,
  SELECTED_PAYMENT,
  SELECTED_UPFRONT_PAYMENT,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { EMethodCode, ICommonData } from '@vfit/shared/models';
import { useCheckout } from '../../../../iBuyFixed.context';
import { ID_FLOWS } from '../../../checkout.constants';
import PreselectedUpfrontPayment from '../PreselectedUpfrontPayment/preselectedUpfrontPayment';
import ChooseUpfrontPayment from '../ChooseUpfrontPayment/chooseUpfrontPayment';
import UpfrontPaymentTagging from './upfrontPayment.tagging';
import { isUpfrontNewMopEnabled } from '../upfrontPaymentCard.utils';

const UpfrontPayment = (props: ICommonData) => {
  const cmsGlobalConfig = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;

  const { setIsLastCard, setCurrentStepKey, setUpdateProductPriceCtrl } = useCheckout();
  const selectedPayment = localStorage.getItem(SELECTED_PAYMENT);
  const selectedPaymentMethodAlreadyPresent = isUpfrontNewMopEnabled(
    cmsGlobalConfig,
    selectedPayment
  );

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.UPFRONT_PAYMENT);
    window.history.pushState(null, '', window.location.pathname);
    setUpdateProductPriceCtrl({
      isPriceUpdated: true,
      isUpdatePrice: false,
    });

    if (selectedPaymentMethodAlreadyPresent)
      localStorage.setItem(SELECTED_UPFRONT_PAYMENT, EMethodCode.CREDIT_CARD);
    setIsLastCard(true);
    return () => {
      setIsLastCard(false);
    };
  }, []);

  return (
    <>
      <UpfrontPaymentTagging />
      {selectedPaymentMethodAlreadyPresent && <PreselectedUpfrontPayment {...props} />}
      {!selectedPaymentMethodAlreadyPresent && <ChooseUpfrontPayment {...props} />}
    </>
  );
};

export default UpfrontPayment;

